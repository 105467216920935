module.exports = function(frequentFlyer) {
  let validator;

  switch (frequentFlyer) {
    case "AC":
      return (validator = {
        pattern: /^\d{9}$/,
        maxlength: 9
      });

    default:
      return (validator = {
        pattern: /^[a-zA-Z0-9]+$/,
        maxlength: 99
      });
  }
};

var Handlebars = require("handlebars/dist/handlebars");
const $ = require("jquery");

// @description Registers formatJS with handlebars
// @usage: See formatJS website
(function() {
  REVELEX = REVELEX || {};
  REVELEX.settings = REVELEX.settings || {};

  var intlData = {
    locales: REVELEX.settings.currentLocale
      ? REVELEX.settings.currentLocale.replace("_", "-")
      : "en-US",
    formats: {
      number: {
        USD: {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2
        },
        USD_round: {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        EUR: {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0
        },
        EUR_round: {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        CAD: {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 0
        },
        CAD_round: {
          style: "currency",
          currency: "CAD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        GBP: {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: 0
        },
        GBP_round: {
          style: "currency",
          currency: "GBP",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        MXN: {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 0
        },
        MXN_round: {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        },
        NZD: {
          style: "currency",
          currency: "NZD",
          minimumFractionDigits: 2
        },
        NZD_round: {
          style: "currency",
          currency: "NZD",
          minimumFractionDigits: 0,
          maximumFractionDigits: 0
        }
      }
    }
  };

  Handlebars.standardCompile = Handlebars.compile;

  Handlebars.compile = function() {
    var template = Handlebars.standardCompile.apply(Handlebars, arguments);
    return function(context) {
      return template(context, {
        data: { intl: intlData }
      });
    };
  };
}.bind(this)());

// @description Increments a number by 1
// @usage: {{increment num}}
Handlebars.registerHelper("increment", function(num) {
  return Number(num) + 1;
});

// @description Allows to evaluate a value in a conditional statement
// @usage:
//    {{#ifvalue variable value="hero"}}
//    it matches, so do some stuff
//    {{/ifvalue}}
Handlebars.registerHelper("ifvalue", function(conditional, options) {
  if (options.hash.value == conditional) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

Handlebars.registerHelper("slugify", function(text) {
  return text
    .toString()
    .toLowerCase()
    .replace(/\s+/g, "-")
    .replace(/[^\w\-]+/g, "")
    .replace(/\-\-+/g, "-")
    .replace(/^-+/, "")
    .replace(/-+$/, "");
});

// @description: return a capitalized string
// @usage:
// {{Capitalize this.name}}  "rey" => "Rey"
Handlebars.registerHelper("Capitalize", function(text) {
  return text.charAt(0).toUpperCase() + text.slice(1);
});

// @description: block helper - find element into Array
// @usage:
// {{#find "Marcos" this.name_list }}  ===> this.name_list = ['Elias','Rey','Marcos']
//  it matches, so do some stuff
// {{else}}
//  so do other stuff
// {{/find}}
Handlebars.registerHelper("find", function(value, arr, options) {
  if (arr.indexOf(value) != -1) {
    return options.fn(this);
  } else {
    return options.inverse(this);
  }
});

// @description: - returns value into array by index passed
// @usage: {{indexOf this.itinerary 2}}
// @note : is the same like say {{this.itinerary.2}} but sometimes 2 is in a variables
// like this {{indexOf this.itinerary @index}}
Handlebars.registerHelper("indexOf", function(array, index, options) {
  if (array instanceof Array && !isNaN(index)) {
    return options.fn(array[Number(index)]);
  } else {
    console.error("check params passed to indexOf helper");
  }
});

// @description: - Good tool for debug
// @usage: {{logConsole param1 param2 ... paramN }} => param1 param2 .. paramN ... + DataObj
Handlebars.registerHelper("logConsole", function() {
  console.log(arguments);
});

Handlebars.registerHelper("setVar", function(varName, value, location) {
  //location will have a hash property if nothing is passed
  var Location = location.hash ? this : location;
  Location[varName] = value;
});

Handlebars.registerHelper("DisplayUndefined", function(value) {
  return value ? value : "N/D";
});

/**
 * @description: Evaluates expression and returns true or false
 */
Handlebars.registerHelper("eval", function(v1, operator, v2) {
  v1 = typeof v1 == "string" ? "'" + v1 + "'" : v1;
  v2 = typeof v2 == "string" ? "'" + v2 + "'" : v2;

  return eval("" + v1 + operator + v2) ? true : false;
});

/**
 * @description: Allows multiple conditional flags to be evaluated at a time
 */
Handlebars.registerHelper("multiFlag", function() {
  var toEval = true;
  for (var i = 0; i < arguments.length - 1; i++) {
    if (!arguments[i]) {
      toEval = false;
      break;
    }
  }
  options = arguments[arguments.length - 1];
  return toEval ? options.fn(this) : options.inverse(this);
});

/**
 * @description: Check if item is contained in array or object
 */
Handlebars.registerHelper("contains", function(array, item) {
  // If it's an array see if the item exists
  if (typeof array == "object" && array.indexOf) {
    return array.indexOf(item) > -1 ? true : false;
  } else if (typeof array == "object" && !array.indexOf) {
    // If it's an object check if the key exists, if it does return the value in that key
    return array[item] ? array[item] : false;
  }
});

// @description:  Allows to evaluate any javascript conditional
// @usage:
// {{#xif this.name "===" "Rey"}}
//   it matches, so do some stuff
// {{else}}
//
// {{/xif}}
Handlebars.registerHelper("xif", function(v1, operator, v2, options) {
  operator = operator.replace("&lt;", "<").replace("&gt;", ">");

  switch (operator) {
    case "==":
      return v1 == v2 ? options.fn(this) : options.inverse(this);
    case "!=":
      return v1 != v2 ? options.fn(this) : options.inverse(this);
    case "===":
      return v1 === v2 ? options.fn(this) : options.inverse(this);
    case "!==":
      return v1 !== v2 ? options.fn(this) : options.inverse(this);
    case "&&":
      return v1 && v2 ? options.fn(this) : options.inverse(this);
    case "||":
      return v1 || v2 ? options.fn(this) : options.inverse(this);
    case "<":
      return v1 < v2 ? options.fn(this) : options.inverse(this);
    case "<=":
      return v1 <= v2 ? options.fn(this) : options.inverse(this);
    case ">":
      return v1 > v2 ? options.fn(this) : options.inverse(this);
    case ">=":
      return v1 >= v2 ? options.fn(this) : options.inverse(this);
    default:
      return eval("" + v1 + operator + v2)
        ? options.fn(this)
        : options.inverse(this);
  }
});

// @description: Allows simple arithmetic to be done
// @usage:
// {{math @index "+" 1}}
// {{math @index "*" @index}}
Handlebars.registerHelper("math", function(lvalue, operator, rvalue, options) {
  lvalue = parseFloat(lvalue || 0);
  rvalue = parseFloat(rvalue || 0);

  return {
    "+": lvalue + rvalue,
    "-": lvalue - rvalue,
    "*": lvalue * rvalue,
    "/": lvalue / rvalue,
    "%": lvalue % rvalue
  }[operator];
});

// @description: For loop handlebars implementation
// @usage:
// {{#for 0 10 1}}{{/for}}
Handlebars.registerHelper("for", function(from, to, incr, block) {
  var accum = "";
  for (var i = from; i < to; i += incr) {
    accum += block.fn(i);
  }
  return accum;
});

Handlebars.registerHelper("formatDateTime", function(
  date,
  formatOut,
  formatIn
) {
  return moment(date, formatIn).format(formatOut);
});

Handlebars.registerHelper("formatUnixDateTime", function(date, formatOut) {
  return moment.utc(moment.unix(date)).format(formatOut);
});

Handlebars.registerHelper("formatTimestampToDate", function(
  timestamp,
  formatOut
) {
  return moment.unix(timestamp).format(formatOut);
});

Handlebars.registerHelper("fixPrice", function(
  num,
  decimals,
  roundtype,
  localeActive
) {
  let currentLocale = REVELEX.settings.currentLocale
    ? REVELEX.settings.currentLocale.replace("_", "-")
    : "en-US";

  currentLocale = localeActive ? currentLocale : undefined;

  num = parseFloat(num) || 0;

  let formattedNum = num;

  if (roundtype === "roundup" || roundtype === "rounddown") {
    //Sometimes there are requirements how the number should be rounded. In this particular case we are passing variable to round up or round down the number
    let roundedValue =
      roundtype === "roundup" ? Math.ceil(num) : Math.floor(num);

    formattedNum = roundedValue.toLocaleString(currentLocale, {
      maximumFractionDigits: 0
    });

    return localeActive ? formattedNum : roundedValue;
  } else {
    decimals = decimals || decimals == 0 ? decimals : 2;

    formattedNum = num.toLocaleString(currentLocale, {
      maximumFractionDigits: decimals,
      minimumFractionDigits: decimals
    });
    //The .toLocaleString formats price with adding comma after thousands
    return localeActive ? formattedNum : num;
  }
});

Handlebars.registerHelper("lowercase", function(str) {
  return str && typeof str != "undefined" ? str.toLowerCase() : "";
});

// Returns first letter of string
Handlebars.registerHelper("firstLetter", function(str) {
  return str.trim().substr(0, 1);
});

// Return first different letter in a list of strings
Handlebars.registerHelper("getFirstLetter", function(str, context) {
  var wordInitial = str
    .replace(/[()\d]/g, "")
    .trim()
    .substr(0, 1);

  if (context.previousLetter !== wordInitial) {
    context.previousLetter = wordInitial;
    return wordInitial;
  } else {
    return false;
  }
});

//Return all different values
Handlebars.registerHelper("matchValue", function(str, context) {
  var itemValue = str;

  if (context.previousItemValue !== itemValue) {
    context.previousItemValue = itemValue;
    return itemValue;
  } else {
    return false;
  }
});

// Truncates string
// @usage:
//{{truncStr someVariable 30}}
Handlebars.registerHelper("truncStr", function(str, num) {
  if (str.length > num) {
    return str.substring(0, num) + "...";
  } else {
    return str;
  }
});

// In any given "string", replace the last occurrence of "source" with "target"
Handlebars.registerHelper("replaceLast", function(string, source, target) {
  return (string = string.replace(new RegExp(source + "$"), target));
});

Handlebars.registerHelper("json", function(context) {
  return JSON.stringify(context);
});

// Gets the length of a object
// @usage:
//{{objLength object}}
Handlebars.registerHelper("objLength", function(obj) {
  return obj ? Object.keys(obj).length : false;
});

/**
 * @description: Helper to highlight partial matches on list components
 * String might contain html tags so we use a regex to match just the text and add highlight markup
 */
Handlebars.registerHelper("highlightMatch", function(item, filterValue) {
  var filteredKeyword = new RegExp("\\b" + filterValue, "gi");

  if (filterValue) {
    // We add a "<" to the item for regex to work properly
    item = (item + "<").replace(
      /([a-z0-9\s\-(),\/]+)(?=\<)/gi,
      function(part) {
        return part.replace(
          filteredKeyword,
          '<span class="highlight">$&</span>'
        );
      }.bind(this)
    );

    // Remove "<"
    item = item.slice(0, -1);

    // If we don't have highlighted elements we underemphasize it with a wrapper
    if (!item.match("highlight")) {
      item = '<span class="no-match">' + item + "</span>";
    }
  }

  return item;
});

/**
 * @description: Check if item is contained in array, string or object
 */
Handlebars.registerHelper("contains", function(set, item) {
  // If it's an array see if the item exists
  if (typeof set == "object" && set.indexOf) {
    var filteredItems = _.filter(set, function(arrItem) {
      return arrItem === item || String(arrItem) === item;
    });
    return filteredItems.length ? item : false;
  } else if (typeof set == "string" && set.indexOf) {
    // If we're checking in a string
    return set.indexOf(item) > -1 ? true : false;
  } else if (typeof set == "object" && !set.indexOf) {
    // If it's an object check if the key exists, if it does return the value in that key
    return set[item] ? set[item] : false;
  }
});

/**
 * @description: Flattens array to one level, extracts only required key
 */
Handlebars.registerHelper("flattenArray", function(array, key) {
  var flattenObject = function(ob) {
    var toReturn = [];

    for (var i in ob) {
      if (!ob.hasOwnProperty(i)) continue;

      if (typeof ob[i] == "object") {
        if (ob[i][key]) {
          toReturn.push(ob[i][key]);
        }

        if (ob[i].data) {
          var flatObject = flattenObject(ob[i].data);
          toReturn = toReturn.concat(flatObject);
        }
      } else {
        toReturn.push(ob[key || 0]);
      }
    }
    return toReturn;
  };

  return flattenObject(array);
});

/**
 * @description: Concatenate arguments
 * @returns: string
 * @example: concat(var1 '-' var2 varN)
 */
Handlebars.registerHelper("concat", function() {
  var stringOutput = "";
  for (var arg in arguments) {
    if (typeof arguments[arg] != "object") {
      stringOutput += arguments[arg].toString();
    }
  }
  return stringOutput;
});

/**
 * @description Raw helper for template sections needing to handle unprocessed blocks
 * @returns template section, string
 * @example {{{{raw}}}} {{your hdbs code}} {{{{/raw}}}}
 */

Handlebars.registerHelper("raw", function(options) {
  return options.fn(this);
});

/**
 * @description: Replacing arguments in string
 * @returns: string
 * @example: replaceString(string 'word to replace' 'replace with')
 */
Handlebars.registerHelper("replaceString", function(str, replace, replaceWith) {
  str = str.toString().replace(replace, replaceWith);
  return typeof str != "undefined" ? str : "";
});

/** add decimal distance to a number
 * @usage:
 * {{precision someVariable 2}}
 */
Handlebars.registerHelper("precision", function(distance, num) {
  return distance.toFixed(num);
});

/** checks length of a string
 * @usage:
 * {{#checklength jobTitle 20}}
      <p>Up to 20</p>
   {{else}}
      <p>Less then 20</p>
   {{/checklength}}
 */

Handlebars.registerHelper("checklength", function(v1, v2, options) {
  "use strict";
  if (v1.trim().length > v2) {
    return options.fn(this);
  }
  return options.inverse(this);
});

Handlebars.registerHelper("encode", function(inputData) {
  return new Handlebars.SafeString(inputData);
});

/**
 * @description: Partial for recursive list building of selection list component
 */
var listPartial = [
  "{{#each data}}",
  " <li>",
  '   <input type="checkbox" {{#if (contains ../selections key)}}checked{{/if}}  id="{{../componentSettings.id}}{{key}}" data-option="{{key}}">',
  '   <label for="{{../componentSettings.id}}{{key}}">{{{highlightMatch name ../filter}}}</label>',
  "   {{#if data}}",
  "     <ul>{{> list filter=../filter}}</ul>",
  "   {{/if}}",
  " </li>",
  "{{/each}}"
].join("");

Handlebars.registerPartial("list", listPartial);

/**
 * Form Component
 */

const s = require("underscore.string");

var ConditionalAttribute = require("./_form.conditional-attributes");

var Form = Backbone.View.extend({
  events: {
    "[data-validate-require-if] change": "requiredIf",
    "click [data-form-reset]": "formReset",
    "focus [data-input-group] *": "inputGroupFocus",
    "blur [data-input-group] *": "inputGroupFocus",
    "input [data-form-casing]": "handleCasing",
    "click [data-validate-options]": "validateMultiple",
    "click [data-toggle-visibility-trigger]": "toggleInputVisibility",
    "paste [data-form-sanitize]": "sanitizeField",
    "input [data-input-sync]": "handleSyncFields",
    "change [data-input-sync]": "handleSyncFields",
    "states-updated [data-country-state-select]": "loadedAsyncComponents",
    "states-loading [data-country-state-select]": "loadingAsyncComponents"
  },

  /**toggle the password visibility and change
   * the icon to represent active vs non-active
   * */

  sanitizeField: function(e) {
    e.preventDefault();

    let currentField = $(e.currentTarget);
    let temporaryTextWrapper = $(
      '<div contenteditable="true" class="is-hidden"></div>'
    );

    const clipboardData = e.originalEvent.clipboardData || window.clipboardData;
    const pastedData =
      clipboardData.getData("text") ||
      clipboardData.getData("text/plain") ||
      clipboardData.getData("text/html");

    // keep current position of content
    var start = currentField.prop("selectionStart");
    var end = currentField.prop("selectionEnd");

    var currentValue = currentField.val();

    $("body").append(temporaryTextWrapper);

    temporaryTextWrapper.html(pastedData);
    var cleanedData = temporaryTextWrapper
      .text()
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/[\u00AD]/g, "");

    temporaryTextWrapper.remove();

    currentField.val(
      currentValue.substring(0, start) +
        cleanedData +
        currentValue.substring(end)
    );
    currentField[0].selectionStart = currentField[0].selectionEnd =
      start + cleanedData.length;
  },
  toggleInputVisibility: function(e) {
    // getting the current trigger, wrapper, input and input type

    let currentTrigger = $(e.currentTarget);
    let wrapper = currentTrigger
      .parents("[data-toggle-visibility-wrapper]")
      .first();
    let input = wrapper.find("[data-toggle-visibility-input]");

    // check for which type is active for the particular
    // input that needs to be toggle
    let type = input.attr("type") === "password" ? "text" : "password";

    input.attr("type", type);

    // toggle the eye / eye slash icon
    currentTrigger.toggleClass("is-active");
  },
  /** toggle between an array of validation properties and run parsley on the actual element
      in the trigger add
      data-validate-options="validation_1|validation_2" separate with pipes
      data-validate-trigger = data-validate-input

      in the input add same data for
      data-validate-group = data-validate-trigger

  **/

  validateMultiple: function(e) {
    let currentElement = $(e.currentTarget);
    let targetElement = this.$(
      "[data-validate-input=" + currentElement.data("validateTrigger") + "]"
    );

    let validateOptions = currentElement.data("validateOptions").split("|");

    if (currentElement.is(":checked")) {
      targetElement.attr("data-validate-string", validateOptions[1]);
    } else {
      targetElement.attr("data-validate-string", validateOptions[0]);
    }

    this.$el
      .parsley()
      .validate({ group: currentElement.data("validateTrigger") });
  },

  /**
   * Force input to have a respective casing
   * **/

  handleCasing: function(e) {
    let currentElement = e.currentTarget;

    // adding position to the casing avoid jumping while replacing string

    let position = currentElement.selectionStart;

    switch ($(currentElement).data("formCasing")) {
      case "uppercase":
        currentElement.value = currentElement.value.toUpperCase();
        break;

      case "lowercase":
        currentElement.value = currentElement.value.toLowerCase();
        break;
      case "capitalize":
        currentElement.value = s.capitalize(currentElement.value);
        break;

      default:
        return;
    }

    currentElement.setSelectionRange(position, position);
  },

  inputGroupFocus: function(e) {
    let currentTarget = $(e.currentTarget);
    let hasActiveDropdown = currentTarget.find(
      "[data-dropdown-wrapper].is-active"
    );
    var action = e.type === "focusin" ? "addClass" : "removeClass";

    if (!hasActiveDropdown) {
      currentTarget.parents("[data-input-group]")[action]("is-focus");
    }
  },

  initialize: function() {
    // Set locale if available through global namespace
    if (REVELEX.settings.currentLocale) {
      try {
        Parsley.setLocale(
          REVELEX.settings.currentLocale.replace("_", "-").toLowerCase()
        );
      } catch (e) {
        Parsley.setLocale(REVELEX.settings.currentLocale.slice(0, 2));
      }
    }

    // Reflow available to set and reset form settings
    this.reflow();

    if (this.$el.data("settings")) {
      this.settings = $.extend(true, this.settings, this.$el.data("settings"));
    }

    // this is done when booking_confirmation is in an iframe
    if (this.settings["formBreakIframe"] && window.self !== window.top) {
      Backbone.Events.trigger("loadingSplashOn");
      parent.location.href = window.location.href;
    }

    var self = this;

    this.submitButton = this.$("[data-submit-button]");

    // this setting is used when Google Recaptcha is visible on page
    if (this.settings.isRecaptcha) {
      // capturing the form submit button
      this.submitButton.prop("disabled", true);

      this.googleRecaptchaCallbacks();
    }

    this.$("[data-form-disable-loader-trigger]").on("click", function() {
      self.disableLoader = true;
    });

    // add listener for click events to validate field groups
    this.$("[data-form-section-validate]").on("click", function() {
      // check if the button has a wrapper
      let currentWrapper = $(this)
        .parents("[data-form-section-valid]")
        .first();

      if (
        currentWrapper.length &&
        currentWrapper.data("formSectionValid") === false
      ) {
        return;
      }

      var group = $(this).data("form-section-validate");

      self.resetValidation();
      var isValid = self.parsley.validate({ group: group });

      var reachedCurrentSection = false;
      var reachedInProgressSection = false;

      self
        .$("[data-form-section-label]")
        .removeClass("is-completed is-waiting in-progress");

      self.$("[data-form-section-label]").each(function() {
        if ($(this).data("form-section-label") === group) {
          reachedCurrentSection = true;
          $(this)[isValid ? "removeClass" : "addClass"]("has-errors");
          $(this)[isValid ? "addClass" : "removeClass"]("is-completed");
        } else {
          if (reachedCurrentSection) {
            if (reachedInProgressSection) {
              $(this)[isValid ? "addClass" : "removeClass"]("is-waiting");
            } else if (isValid) {
              reachedInProgressSection = true;
              $(this)[isValid ? "addClass" : "removeClass"]("in-progress");
            }
          } else if (!$(this).hasClass("has-errors")) {
            $(this).addClass("is-completed");
          }
        }
      });

      return isValid;
    });

    // add listener for form submission validation error to update form section labels on error
    this.parsley.on("form:error", function(formInstance) {
      for (var idx in formInstance.fields) {
        if (formInstance.fields[idx].getErrorsMessages().length) {
          _.each(formInstance.fields[idx].domOptions.group, function(group) {
            if (group.length) {
              self
                .$('[data-form-section-label="' + group + '"]')
                .addClass("has-errors");
            }
          });
        }
      }
    });

    $("[data-input-sync]")
      .trigger("input", true)
      .trigger("change", true);
  },

  googleRecaptchaCallbacks: function() {
    // global callback functions used for recaptcha validation
    // if valid will enable the button
    window.enableButton = () => {
      this.submitButton.prop("disabled", false);
    };
    // if google Recaptcha expires
    window.recaptchaExpired = () => {
      this.submitButton.prop("disabled", true);
    };
  },

  /**
   * Parsley validator settings
   */
  setSettings: function() {
    // Initializing validation handlers to display errors
    let ParsleyClassHandler = function(el) {
      // looking for parent element to wrap validators
      let customElementParent = el.$element.parents(
        ".form-field, [data-form-group]"
      );

      // if there's no expected custom element parent
      // just go after the parent
      return customElementParent.length
        ? customElementParent.first()
        : el.$element.parent();
    };

    // Error handler created separately to render error as close from the field.
    // if the wrapper is a group will add it to the whole group
    let ParsleyErrorHandler = function(el) {
      let currentElement = el.$element;

      let parentOptions =
        ".input-has-icon, .select-has-icon, .input-group, [data-input-wrapper], .form-field-optional-wrapper, [data-input-group]";

      let currentElementParent = currentElement.parents(parentOptions);

      let currentField = currentElement.parents(".form-field");

      // check if the current element is wrapper
      // with a has icon class
      let currentWrapper = currentElementParent.length
        ? parentOptions
        : "input, select";

      let errorWrapper = "<div class='form-errors-wrapper'></div>";

      // check if element is inside a from-group
      let isElementGroup = currentElement.parents("[data-form-group]");

      // if it's not in a group go after direct parent
      let parentWrapper = isElementGroup.length
        ? isElementGroup.first()
        : currentElement.parent();

      let currentElementWrapper = currentField
        .first()
        .find(currentWrapper)
        .next();

      //Will wrap element in a div making the error stay in position
      if (
        !currentElement.is(":checkbox, :radio") ||
        currentElement.parents("[data-input-wrapper]").length
      ) {
        currentElementParent.length
          ? currentElementParent.wrap(errorWrapper)
          : currentElement.wrap(errorWrapper);

        currentElementWrapper = currentElementParent.length
          ? currentField
              .first()
              .find(currentWrapper)
              .first()
              .parent()
          : currentField
              .first()
              .closest(currentWrapper)
              .next();
      }

      // If the input is inside a form-field use it as a
      // error wrapper and go after it's current wrapper.
      // If not in a field check if its inside of a group
      return currentField.length ? currentElementWrapper : parentWrapper;
    };

    // Default settings for Parsley validator
    this.parsleySettings = {
      namespace: "data-validate-",
      errorClass: "is-invalid",
      successClass: "is-valid",
      errorsWrapper: '<ul class="form-errors-list"></ul>',
      classHandler: ParsleyClassHandler,
      errorsContainer: ParsleyErrorHandler,
      formPopulate: false,
      formTriggerLoader: true,
      formDisableButtonsOnSubmit: true,
      triggerAfterFailure: "input change blur",
      // define fields parsley should ignore when validating
      excluded:
        "input[type=button], input[type=submit], input[type=hidden], input[type=reset], [disabled], [readonly]"
    };

    // Store data attributes as component settings
    this.settings = this.$el.data() || {};

    // if data-settings attr, merge with default settings, data attribute settings will take priority
    if (this.settings["formSettings"]) {
      this.parsleySettings = _.extend(
        this.settings["formSettings"],
        this.parsleySettings
      );
    }

    return this;
  },

  reflow: function() {
    // Set up settings and set Parsley
    this.setSettings().resetValidation();

    // Enable inputmask in data-mask inputs
    this.$("[data-mask]").each(
      function(i, el) {
        // Pull mask from our Mask Library or pass in data-mask attr if not declared.
        var mask =
          $.fn.inputmask.library[$(el).data("mask")] || $(el).data("mask");

        $(el).inputmask(mask);
      }.bind(this)
    );

    // Pre-population
    if (this.settings.formPopulate) {
      this.populate(this.settings.formPopulate);
    }

    // Register conditional require
    this.registerConditionalAttribute({
      name: "require",
      attr: "required"
    });

    // Register conditional disable
    this.registerConditionalAttribute({
      name: "disable",
      attr: "disabled"
    });

    // Register conditional check
    this.registerConditionalAttribute({
      name: "check",
      prop: "checked"
    });

    //NOTE: data-form-load attribute, add it to your form when you want to
    // have correctly initialized components. This is the way all components should load.
    // Loading nested components is a parent component responsibility. Form should not do it.
    if (this.settings["formLoad"]) {
      this.$el.loadComponents();
      this.$el.loadModules();
    } else {
      // @TODO: Remove this condition
      // Init all children components
      this.$("[data-component]").each(function() {
        $(this).component();
      });
      this.$("[data-module]").each(function() {
        $(this).module();
      });
    }
    return this;
  },
  toJSON: function() {
    // Objectify form data
    var formArray = _.map(this.$el.serializeArray(), _.values);
    var formData = {};

    // Map through the array of values
    _.each(
      formArray,
      function(field) {
        var key = field[0],
          val = field[1];

        // If not contained, add key value
        if (!formData[key]) {
          formData[key] = field[1];
        } else if (_.isArray(formData[key])) {
          // If array, add value
          formData[key].push(val);
        } else {
          // If key exists but contains a string, turn into array.
          var newVal = [];
          newVal.push(formData[key]);
          newVal.push(val);
          formData[key] = newVal;
        }
      }.bind(this)
    );

    return formData;
  },
  validate: function(attributes) {
    var settings = {
      reset: true
    };

    settings = _.extend(settings, attributes);

    // Reflow Validation
    if (settings.reset) {
      this.resetValidation();
    }

    // Return form validation boolean
    return this.parsley.validate(attributes);
  },

  resetValidation: function(e) {
    if (!this.parsley) {
      // Store instance of Parsley in view
      this.parsley = this.$el.parsley(this.parsleySettings);
    } else {
      // Reset form
      this.parsley.reset();
    }

    // Optional binding to activate global loading splash if form is valid
    if (
      this.settings["formTriggerLoader"] ||
      this.settings["formDisableButtonsOnSubmit"]
    ) {
      this.parsley.on(
        "form:submit",
        function(currentForm) {
          // Disable submit buttons to avoid duplicate submissions
          if (this.settings["formDisableButtonsOnSubmit"]) {
            this.$("button").attr("disabled", true);
          }

          // Trigger global loader
          if (this.settings["formTriggerLoader"] && !this.disableLoader) {
            // checking if the submit button has a message, if yes use it,
            // if not use the one attached to the form tag

            let currentLoaderTitle =
              typeof currentForm.submitEvent.originalEvent !== "undefined"
                ? $(currentForm.submitEvent.originalEvent.submitter)
                : currentForm.$element.find("[data-submit-form]");

            let currentTitleScope = currentLoaderTitle.data(
              "loadingSplashCustomTitle"
            )
              ? currentLoaderTitle
              : currentForm;

            Backbone.Events.trigger("loadingSplashOn", currentTitleScope);
          }

          this.disableLoader = false;
        }.bind(this)
      );
    }

    return this;
  },
  isValid: function() {
    // Map to Parsley isValid method.
    return this.parsley.isValid();
  },
  populate: function(formData) {
    // Map passed-in object into form fields
    _.each(
      formData,
      function(value, key) {
        var field = this.$('[name="' + key + '"]');

        // Only  update if there are matches with used selector
        if (field.length) {
          field[0].value = value;
        }
      }.bind(this)
    );
    return this;
  },
  registerConditionalAttribute: function(settings) {
    // If no events provided, bind to change event
    settings.event = _.has(settings, "event") ? settings.event : "change";

    // Find all conditional attributes fields for this specific registration in current Form
    this.$("[data-form-" + settings.name + "-if]").each(
      function(i, el) {
        // Store the name with the first letter uppercase for camelCase selectors
        settings.Name =
          settings.name.charAt(0).toUpperCase() + settings.name.slice(1);

        new ConditionalAttribute({
          el: el,
          form: this.$el,
          settings: settings
        });
      }.bind(this)
    );

    return this;
  },

  // Check if element is excluded from the reset list (formReset method)
  isExcluded: function(list, element) {
    //variable created due to non-breaking return inside _.each loop
    var found = false;
    _.each(list, function(selector) {
      if ($(element).is(selector)) {
        found = true;
        return false;
      }
    });
    return found;
  },

  /*
   ** Global reset method
   **/
  formReset: function(e) {
    //If we pass a list of items to exclude from the reset we edit the selector
    var exclude = e.currentTarget.dataset.formResetExclude || "";
    excludedComponents = exclude
      .replace(/(?!\|)[^\|]+/g, ":not($&)")
      .replace(/\|/g, "");
    excludedInputs = exclude.split("|");

    // Clear all native input components
    _.each(
      this.el.elements,
      function(element) {
        if (typeof element !== "undefined") {
          if (element.type == "hidden") {
            // Don't clear hidden inputs
            return false;
          } else {
            // Don't clear excluded inputs
            if (this.isExcluded(excludedInputs, element)) {
              return false;
            }

            if (element.tagName == "SELECT") {
              // Select change selection to default option
              var defaultValue = $(element).find("option:selected")[0].index;
              element.selectedIndex = defaultValue.length ? defaultValue : 0;
              $(element).trigger("change");
            } else if (element.type == "radio" || element.type == "checkbox") {
              // Uncheck radios and checkboxes
              element.checked = false;
            } else {
              // Clear all other inputs
              element.value = "";
            }
          }
        }
      }.bind(this)
    );

    // Reset all components
    this.$("[data-component]" + excludedComponents).each(function() {
      if ($(this).data("initialized")) {
        $(this)
          .component()
          .reset();
      }
    });
  },
  stripSpace: function(e) {
    e.currentTarget.value = e.currentTarget.value.trim();
  },

  /**
   *
   * @param e
   */
  handleSyncFields: function(e, isInit) {
    const originalField = $(e.currentTarget);
    const syncFieldValue = originalField.attr("data-input-sync");

    const syncField = $("#" + syncFieldValue);

    if (isInit && !originalField.val()) {
      return false;
    }

    // Check if the value is different to avoid infinite loop
    if (syncField.val() !== originalField.val()) {
      syncField.val(originalField.val());

      // If the syncField is a select element,
      // trigger the change event to ensure proper update
      if (syncField.is("select")) {
        syncField.trigger("change");
      }
    }
  },

  loadedAsyncComponents: function() {
    if (!this.submitOriginalStatus && this.submitButtons?.length) {
      this.submitButtons?.prop("disabled", false);
    }
  },

  loadingAsyncComponents: function() {
    this.submitButtons = this.$("[data-submit-button], [data-dialog-action]");
    this.submitOriginalStatus = this.submitButtons?.is(":disabled");

    this.submitButtons?.prop("disabled", true);
  }
});

module.exports = Form;
